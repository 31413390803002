import React from "react";
import Terms from "../components/terms/terms";
import Layout from "../components/layout/index";
import Seo from "../components/seo";

const index = () => {
  return (
    <Layout>
      <Seo slug="/terms-n-conditions/" />
      <Terms />
    </Layout>
  );
};

export default index;
