import React from "react";

const Terms = () => {
  return (
    <div className="container py-40 mx-auto px-4">
      <h1 className="text-left font-[400] text-[24px] leading-[28px] text-[#1F1F39] font-poppins pb-[50px]">
        Terms and Conditions
      </h1>
      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        Below are the Terms and Conditions for use of https://www.teenyfy.com/.
        Please read these carefully. If you need to contact us regarding any
        aspect of the following terms of use of our website, please contact us
        on the following email address - info@teenyfy.com.
      </p>
      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        By accessing the content of www.teenyfy.com ( hereafter referred to as
        website ) you agree to the terms and conditions set out herein and also
        accept our Privacy Policy. If you do not agree to any of the terms and
        conditions you should not continue to use the Website and leave
        immediately.
      </p>
      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        You agree that you shall not use the website for any illegal purposes,
        and that you will respect all applicable laws and regulations.
      </p>
      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        You agree not to use the website in a way that may impair the
        performance, corrupt or manipulate the content or information available
        on the website or reduce the overall functionality of the website.
      </p>
      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        You agree not to compromise the security of the website or attempt to
        gain access to secured areas of the website or attempt to access any
        sensitive information you may believe exist on the website or server
        where it is hosted.
      </p>

      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        You agree to be fully responsible for any claim, expense, losses,
        liability, costs including legal fees incurred by us arising from any
        infringement of the terms and conditions in this agreement and to which
        you will have agreed if you continue to use the website.
      </p>

      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        The reproduction, distribution in any method whether online or offline
        is strictly prohibited. The work on the website and the images, logos,
        text and other such information is the property of www.teenyfy.com (
        unless otherwise stated ).
      </p>

      <h2 className="text-left font-[400] text-[24px] leading-[28px] text-[#1F1F39] font-poppins pb-[50px] py-[70px]">
        Disclaimer
      </h2>

      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        Though we strive to be completely accurate in the information that is
        presented on our site, and attempt to keep it as up to date as possible,
        in some cases, some of the information you find on the website may be
        slightly outdated.
      </p>

      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        www.teenyfy.com reserves the right to make any modifications or
        corrections to the information you find on the website at any time
        without notice.
      </p>

      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        Change to the Terms and Conditions of Use
      </p>

      <p className="text-left font-[400] text-[16px] pb-[15px] leading-[24px] text-[#1F1F39] font-open-sans ">
        We reserve the right to make changes and to revise the above mentioned
        Terms and Conditions of use.
      </p>
    </div>
  );
};

export default Terms;
